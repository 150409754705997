import Image from "next/image";
import Link from "next/link";
import React from "react";
import { Fade } from "react-reveal";

function MajorFeatures() {
  return (
    <section className="container py-[68px] px-[20px] md:py-[98px] md:px-[24px] lg:py-[118px] lg:px-0   border border-x-0 border-t-0 dark:border-gray-200">
      <div>
        <div className=" dark:bg-jacarta-900  text-center  ">
          <div className="grid grid-cols-1 gap-8 md:gap-10 lg:grid-cols-3">
            <Fade ssrReveal bottom cascade delay={350}>
              <div className="w-full lg:w-[100%] md:px-20 lg:px-2">
                <Image
                  width={500}
                  height={100}
                  className=" mx-auto z-[-1]"
                  alt="Readable-Wallets"
                  src="/images/features/f-3.svg"
                />
                <div className="px-0">
                  <h2 className="mb-5 font-medium text-[28px]">
                    Readable Wallets
                  </h2>
                  <p className="text-[18px] text-jacarta-900 dark:text-jacarta-400">
                    Replace complex wallet addresses with easy-to-remember
                    names, simplifying the process of sending and receiving
                    digital assets to be as effortless as sending a text
                    message.
                  </p>
                </div>
              </div>
              <div className="w-full lg:w-[100%] md:px-20 lg:px-2">
                <Image
                  width={500}
                  height={100}
                  className=" mx-auto  z-[-1]"
                  alt="Blockchain-Domain"
                  src="/images/features/f-2.svg"
                />
                <div className="px-0">
                  <h2 className="mb-5 font-medium text-[28px]">
                    Digital Identity
                  </h2>
                  <p className="text-[18px] text-jacarta-900 dark:text-jacarta-400">
                    To link your domain to a crypto wallet or display it as a
                    username on a dApp, it needs to be a complete domain like
                    "your.name"
                  </p>
                </div>
              </div>
              <div className="w-full lg:w-[100%] md:px-20 lg:px-2">
                <Image
                  width={500}
                  height={100}
                  className=" mx-auto  z-[-1]"
                  alt="Web3-Identity"
                  src="/images/features/f-1.svg"
                />
                <div className="px-0">
                  <h2 className="mb-5 font-medium text-[28px]">
                    Lifetime Ownership
                  </h2>
                  <p className="text-[18px] text-jacarta-900 dark:text-jacarta-400">
                    You have full control over your domains: own , manage, or
                    sell. Pay once, and never worry about renewal fees again
                  </p>
                </div>
              </div>
            </Fade>
          </div>

          <div className="flex items-center justify-center mt-20">
            <Link
              href="/ai-domains"
              className="items-center  justify-center rounded-md text-[18px] font-medium  bg-gradient-to-tr from-hotPink via-purplish to-accent py-[22px] px-[32px] md:py-[24px] md:px-[38px] cursor-pointer button z-[2] transition-all duration-500 text-white hover:-translate-y-1 text-center w-full md:w-[20%]"
            >
              Try AI Domains
            </Link>
          </div>
        </div>
      </div>
    </section>
  );
}

export default MajorFeatures;
