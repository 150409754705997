import { useTheme } from "next-themes";
import Image from "next/image";
import React from "react";
import Marquee from "react-fast-marquee";
const Eco = ({ onlyWallets }) => {

  const partnerItem = [

    {
      img: 'infura',
      img_dark: 'infura-dark',
      alt: 'infura-logo'
    },
    {
      img: 'tangem',
      img_dark: 'tangem-dark',
      alt: 'tangem-logo',
      type: 'wallet'
    },
    {
      img: 'openai',
      img_dark: 'openai-dark',
      alt: 'openai-logo'
    },
    {
      img: 'metamask',
      img_dark: 'metamask-dark',
      alt: 'metamask-logo',
      type: 'wallet'

    },
    // {
    //   img: 'ipfs',
    //   img_dark: 'ipfs-dark',
    //   alt: 'ipfs-logo'
    // },
    {
      img: 'polygon',
      img_dark: 'polygon-dark',
      alt: 'polygon-logo'
    },
    {
      img: 'thirdWeb',
      img_dark: 'thirdWeb-dark',
      alt: 'thirdweb-logo'
    },
    {
      img: 'trusted-wallet',
      img_dark: 'trusted-wallet-dark',
      alt: 'trusted-wallet-logo',
      type: 'wallet'

    },
    {
      img: 'wallet-connect',
      img_dark: 'wallet-connect-dark',
      alt: 'wallet-connect-logo',
      type: 'wallet'

    },
    {
      img: 'paper',
      img_dark: 'paper-dark',
      alt: 'paper-logo',
      type: 'wallet'

    },
    {
      img: 'rainbow',
      img_dark: 'rainbow-dark',
      alt: 'rainbow-logo',
      type: 'wallet'

    },



  ];

  const partnerItem2 = [

    {

      img: 'infura-dark',
      alt: 'infura-logo'
    },
    {

      img: 'tangem-dark',
      alt: 'tangem-logo',
      type: 'wallet'
    },
    {

      img: 'openai-dark',
      alt: 'openai-logo'
    },

    {

      img: 'metamask-dark',
      alt: 'metamask-logo',
      type: 'wallet'

    },
    // {

    //   img: 'ipfs-dark',
    //   alt: 'ipfs-logo'
    // },
    {

      img: 'polygon-dark',
      alt: 'polygon-logo'
    },
    {

      img: 'thirdWeb-dark',
      alt: 'thirdweb-logo'
    },
    {
      img: 'trusted-wallet-dark',

      alt: 'trusted-wallet-logo',
      type: 'wallet'

    },
    {
      img: 'wallet-connect-dark',

      alt: 'wallet-connect-logo',
      type: 'wallet'

    },
    {
      img: 'paper-dark',

      alt: 'paper-logo',
      type: 'wallet'

    },

    {
      img: 'rainbow-dark',

      alt: 'rainbow-logo',
      type: 'wallet'

    },



  ];

  const { theme } = useTheme();
  const [items, setItems] = React.useState(partnerItem)

  React.useEffect(() => {
    if (theme === 'dark' || theme === 'system') {
      setItems(onlyWallets ? partnerItem2.filter(it => it.type === "wallet") : partnerItem2.filter(it => it.type !== "wallet"))
    } else {
      setItems(onlyWallets ? partnerItem.filter(it => it.type === "wallet") : partnerItem.filter(it => it.type !== "wallet"))
    }
  }, [theme, onlyWallets])

  return (

    <Marquee className="space-x-8">


      {(items).map((item, i) => (
        <div
          className="flex flex-shrink-0 items-center justify-center rounded-2.5xl md:px-6  "
          key={i}
        >
          {/* <div className=" w-[90px] h-[100px] md:h-[120px] lg:h-[150px] md:w-[100%] flex items-center justify-center "> */}
          <div className=" w-[105px] h-[110px] md:h-[120px] lg:h-[150px] md:w-[100%] flex items-center justify-center ">
            <Image
              width={250}
              height={190}
              className="object-cover rounded-[10px] "

              src={`/images/eco/${item.img}.png`}

              alt={item.alt}

            />
          </div>
        </div>
      ))
      }

    </Marquee>

  );
};

export default Eco;
