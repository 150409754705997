import Link from "next/link";
import React, { useCallback, useRef, useState } from "react";
import { FaStar } from 'react-icons/fa'
import { Fade } from "react-reveal";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, } from "swiper/modules";

const Premium = () => {

    const sliderRef = useRef(null);

    const handleSlideChange = () => {
        if (sliderRef.current) {
            const swiper = sliderRef.current.swiper;
            setIsAtBeginning(swiper.isBeginning);
            setIsAtEnd(swiper.isEnd);
        }
    };
    const [isAtBeginning, setIsAtBeginning] = useState(true);
    const [isAtEnd, setIsAtEnd] = useState(false);
    const handlePrev = useCallback(() => {
        if (!sliderRef.current) return;
        sliderRef.current.swiper.slidePrev();
        console.log('prev slide')
    }, []);

    const handleNext = useCallback(() => {
        if (!sliderRef.current) return;
        sliderRef.current.swiper.slideNext();
        console.log('next slide')
    }, []);

    const domain = [
        {
            id: 0,
            name: '.cryptobull',
            price: '$ 10.00'
        },
        {
            id: 1,
            name: '.rarenft',
            price: '$ 10.00'
        },
        {
            id: 2,
            name: '.gamerpro',
            price: '$ 10.00'
        },
        {
            id: 3,
            name: '.bitwallet',
            price: '$ 10.00'
        },
        {
            id: 3,
            name: '.pulse',
            price: '$ 10.00'
        },
    ]
    return (
        <>
            <div className="container py-[68px] px-[20px] md:py-[98px] md:px-[24px] lg:py-[118px] lg:px-0 border border-x-0 border-t-0 dark:border-gray-200">
                <Fade ssrReveal bottom delay={350}>

                    <div>
                        <h1 className="text-jacarta-900 font-light font-display mb-4 text-center text-[30px] md:text-[44px] dark:text-white  lg:text-[64px] xl:text-[68px] max-w-[500px] md:max-w-[600px] lg:max-w-[850px] mx-auto leading-tight	">
                            <span className="animate-gradient"> Top-Level Domain</span>
                        </h1>
                        <p className="mb-[28px] text-center text-[16px] md:text-[18px] font-normal max-w-[500px] md:max-w-[450px] lg:max-w-[450px] mx-auto">
                            Own your Web3 identity with AI-powered tools, domain minting, and cold storage solutions - all in one place.
                        </p>
                    </div>
                    <div className="">
                        <div className=" gradient-box  bg-white dark:bg-jacarta-900">
                            <div className=" p-10 rounded-sm">
                                <Swiper
                                    ref={sliderRef}
                                    onSlideChange={handleSlideChange}
                                    spaceBetween={20}
                                    modules={[Navigation]}
                                    breakpoints={{
                                        240: {
                                            slidesPerView: 1,
                                        },
                                        565: {
                                            slidesPerView: 3,
                                        },
                                        995: {
                                            slidesPerView: 4,
                                        },
                                    }}
                                    className="!p-2 card-slider-4-columns"

                                    style={{
                                        "--swiper-navigation-color": "#fff",
                                        "--swiper-pagination-color": "#fff",
                                    }}

                                    navigation={{
                                        nextEl: '.swiper-button-next',
                                        prevEl: '.swiper-button-prev',
                                    }}
                                >
                                    {domain.map((item) => {

                                        return (
                                            <SwiperSlide className='bg-gray-300 p-4 dark:bg-gray-200 hover:bg-gradient-to-tr hover:from-hotPink hover:via-purplish hover:to-accent'>
                                                <Link key={item.id} href={`/domains?q=${item.name}`} className=" transition-all duration-500 hover:-translate-y-1 cursor-pointer">
                                                    <div className="flex gap-2 items-center">
                                                        <FaStar style={{ fill: "#02f6d1" }} />
                                                        <p className="text-[14px]">Premium</p>
                                                    </div>
                                                    <div className="p-4 mt-4 text-center">
                                                        <p className="text-2xl lg:text-3xl font-bold pb-2">{item.name}</p>
                                                        <p className="pb-1">SLDs starting at:</p>
                                                        <p className="text-[24px] font-bold">{item.price}</p>
                                                    </div>
                                                </Link>
                                            </SwiperSlide>
                                        );
                                    })}
                                </Swiper>


                            </div>

                        </div>
                        <div onClick={handlePrev} className="swiper-button-prev flex justify-center items-center  cursor-pointer">
                            {/* <FiChevronLeft className=" w-6 h-6  " /> */}
                        </div>
                        <div onClick={handleNext} className="swiper-button-next flex justify-center items-center  cursor-pointer">
                            {/* <FiChevronRight className="  w-6 h-6" /> */}
                        </div>
                    </div>

                </Fade>
                <svg width="1em" height="1em">
                    <defs>
                        <linearGradient id={"blue-gradient"} x1="1" x2="0" y1="0" y2="1">
                            <stop offset="0%" stop-color=" #02f6d1 " />
                            <stop offset="50%" stop-color="#804AE4" />

                            <stop offset="100%" stop-color="#FF69B4" />
                        </linearGradient>

                    </defs>
                </svg>

            </div>
        </>
    )
}

export default Premium;