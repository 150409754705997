import Image from "next/image";
import React from "react";
const data = [
  {
    title: "“The best crypto app”",
    desc: "Lorem ipsum dolor sit amet, consectetur adipiscing elit ultrices quam posuere at sed. In donec phasellus velit pellentesque.",
    name: "Sophie Moore",
    designation: "Head of Operations at Company",
  },
  {
    title: "“The app for crypto enthusiasts”",
    desc: "Lorem ipsum dolor sit amet, consectetur adipiscing elit ultrices quam posuere at sed. In donec phasellus velit pellentesque.",
    name: "Lily Woods",
    designation: "VP of Marketing at Corporation",
  },
  {
    title: "“I simply love this app”",
    desc: "Lorem ipsum dolor sit amet, consectetur adipiscing elit ultrices quam posuere at sed. In donec phasellus velit pellentesque.",
    name: "Matt Cannon",
    designation: "VP of Sales at Startup",
  },
];
function Testimonoals() {
  return (
    <section>
      <div className="container py-[68px] px-[20px] md:py-[98px] md:px-[24px] lg:py-[118px] lg:px-0  border border-x-0 dark:border-gray-200 ">
        <div className="grid md:grid-cols-2 gap-0">
          <div className="lg:mr-14 ">
            {data.map((item) => {
              return (
                <div className="py-10 border border-x-0 border-t-0 dark:border-gray-200 last:border-0">
                  <h2 className="text-lg lg:text-xl mb-4 font-medium">{item.title}</h2>
                  <p className="text-base lg:text-[18px] mb-7 !text-[#b5b6bb]">
                    {item.desc}
                  </p>
                  <p className="text-base lg:text-[18px] mb-2">{item.name}</p>
                  <p className="inline-block text-base lg:text-[18px] text-gradient">
                    {item.designation}
                  </p>
                </div>
              );
            })}
          </div>
          <div className="border dark:border-gray-200 rounded-large pt-[56px] pr-[48px] pb-[23px] pl-[30px] relative overflow-hidden h-[750px] lg:h-full">
            <div>
              <h2 className="text-[26px] lg:text-[38px] mb-5 font-medium">
                “The best crypto app”
              </h2>
              <p className="mb-8 text-base lg:text-[18px] leading-7 !text-[#b5b6bb]">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit neque
                vivamus adipiscing sed feugiat turpis viverra faucibus consequat
                augue diam commodo ornare libero, elit pellentesque.
              </p>
              <p className="text-base lg:text-[18px] mb-3">John Carter</p>
              <p className="text-base lg:text-[18px]">CEO at Consulting</p>
            </div>
            <div
              className={` w-full absolute  z-[10] top-[50%] mix-blend-color-dodge`}
            >
              <div className="relative  h-[600px] w-[1000px]  animate-fade">
                <Image
                  fill
                  sizes="100vw"
                  src="/images/hero2.webp"
                  alt="dots-background"
                  className="object-cover "
                />
              </div>
            </div>
            <div className="absolute -right-16 bottom-4 z-[20]">
              <Image src="/images/aboutC.svg" width={506} height={463} className="object-contain w-[350px] lg:w-[506px]" />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Testimonoals;
