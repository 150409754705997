import React, { useCallback, useRef } from "react";
import Carousal from "./carousal";
import Link from "next/link";
const Support = () => {




    return (
        <section>
            <div className="container py-[68px] px-[20px] md:py-[98px] md:px-[24px] lg:py-[118px] lg:px-0  ">


                <div className="">
                    <Carousal />

                </div>

          


            </div>
        </section>
    )
}

export default Support;