import { useState } from "react";
import {
  MdAdd,
  MdRemove,
  MdPermIdentity,
  MdSyncAlt,
  MdOutlineSecurity,
} from "react-icons/md";
import { BsCurrencyDollar } from "react-icons/bs";

import {
  Accordion,
  AccordionHeader,
  AccordionBody,
} from "@material-tailwind/react";

function Icon({ id, open }) {
  return (
    <>
      {open === id ? (
        <MdRemove className="h-[30px] w-[30px] transition-transform" />
      ) : (
        <MdAdd className="h-[30px] w-[30px] transition-transform" />
      )}
    </>
  );
}

const FaqAccordion = ({ setFaq }) => {
  const [open, setOpen] = useState(1);

  const handleOpen = (value) => {
    setFaq(value);
    setOpen(open === value ? 0 : value);
  };

  return (
    <div className="mb-14">
      <div className="flex gap-2 md:gap-6 items-center  border border-x-0 border-t-0 border-gray-200">
        <div
          className={` ${
            open === 1
              ? "bg-gradient-to-tr from-hotPink via-purplish to-accent  "
              : "dark:bg-jacarta-800  bg-gray-300"
          }  p-4 rounded-lg  `}
        >
          <a className="cursor-pointer">
            {" "}
            <MdPermIdentity className="w-[20px] md:w-[30px] h-[20px] md:h-[30px] text-white" />
          </a>
        </div>

        <Accordion
          className="my-[30px] md:my-[50px] overflow-hidden rounded-lg "
          open={open === 1}
          icon={<Icon id={1} open={open} />}
        >
          <AccordionHeader
            className="accordion-button relative flex w-full items-center justify-between bg-white px-4 py-3 text-left font-display text-jacarta-900 dark:bg-jacarta-900 dark:text-white text-[20px] md:text-[24px]"
            onClick={() => handleOpen(1)}
          >
            Search
          </AccordionHeader>
          <AccordionBody className="accordion-body bg-white p-4 dark:border-jacarta-600 dark:bg-jacarta-900 h-full text-base">
            Search for your desired domain, and our smart contracts will display
            its availability and price (e.g., your.name or your.brand).
          </AccordionBody>
        </Accordion>
      </div>
      <div className="flex gap-2 md:gap-6 items-center  border border-x-0 border-t-0 border-gray-200">
        <div
          className={` ${
            open === 2
              ? "bg-gradient-to-tr from-hotPink via-purplish to-accent  "
              : "dark:bg-jacarta-800  bg-gray-300"
          }  p-4 rounded-lg  `}
        >
          <a className="cursor-pointer">
            {" "}
            <MdSyncAlt className="w-[20px] md:w-[30px] h-[20px] md:h-[30px] text-white" />
          </a>
        </div>

        <Accordion
          className="my-[30px] md:my-[50px] overflow-hidden rounded-lg "
          open={open === 2}
          icon={<Icon id={2} open={open} />}
        >
          <AccordionHeader
            className="accordion-button relative flex w-full items-center justify-between bg-white px-4 py-3 text-left font-display text-jacarta-900 dark:bg-jacarta-900 dark:text-white text-[20px] md:text-[24px]"
            onClick={() => handleOpen(2)}
          >
            Buy
          </AccordionHeader>
          <AccordionBody className="accordion-body bg-white p-4 dark:border-jacarta-600 dark:bg-jacarta-900 h-full text-base">
            Create a crypto wallet via email if you don't have one, or log into
            your account. Once you make the payment, the domain is minted onto
            the Polygon blockchain and simultaneously placed in your smart
            wallet.
          </AccordionBody>
        </Accordion>
      </div>

      <div className="flex gap-2 md:gap-6 items-center  border border-x-0 border-t-0 border-gray-200">
        <div
          className={` ${
            open === 3
              ? "bg-gradient-to-tr from-hotPink via-purplish to-accent  "
              : "dark:bg-jacarta-800  bg-gray-300"
          }  p-4 rounded-lg  `}
        >
          <a className="cursor-pointer">
            {" "}
            <BsCurrencyDollar className="w-[20px] md:w-[30px] h-[20px] md:h-[30px] text-white" />
          </a>
        </div>

        <Accordion
          className="my-[30px] md:my-[50px] overflow-hidden rounded-lg "
          open={open === 3}
          icon={<Icon id={3} open={open} />}
        >
          <AccordionHeader
            className="accordion-button text-[20px] md:text-[24px] relative flex w-full items-center justify-between bg-white px-4 py-3 text-left font-display text-jacarta-900 dark:bg-jacarta-900 dark:text-white "
            onClick={() => handleOpen(3)}
          >
            Manage
          </AccordionHeader>
          <AccordionBody className="accordion-body bg-white p-4 dark:border-jacarta-600 dark:bg-jacarta-900 h-full text-base">
            Once you purchase a TLD or a full domain (SLD + TLD), as the TLD
            owner, you will soon be able to buy and sell an unlimited number of
            SLDs, driving revenue and brand loyalty for your community.
          </AccordionBody>
        </Accordion>
      </div>
      <div className="flex gap-2 md:gap-6 items-center">
        <div
          className={` ${
            open === 4
              ? "bg-gradient-to-tr from-hotPink via-purplish to-accent  "
              : "dark:bg-jacarta-800  bg-gray-300"
          }  p-4 rounded-lg  `}
        >
          <a className="cursor-pointer">
            {" "}
            <MdOutlineSecurity className="w-[20px] md:w-[30px] h-[20px] md:h-[30px] text-white" />
          </a>
        </div>
        <Accordion
          className="my-[30px] md:my-[50px] overflow-hidden rounded-lg "
          open={open === 4}
          icon={<Icon id={4} open={open} />}
        >
          <AccordionHeader
            className="accordion-button text-[20px] md:text-[24px] relative flex w-full items-center justify-between bg-white px-4 py-3 text-left font-display text-jacarta-900 dark:bg-jacarta-900 dark:text-white "
            onClick={() => handleOpen(4)}
          >
            Secure
          </AccordionHeader>
          <AccordionBody className="accordion-body bg-white p-4 dark:border-jacarta-600 dark:bg-jacarta-900 h-full text-base">
            Enhance the security of your digital assets with a Tangem Hard
            Storage Wallet Card —over 100,000,000 sold with zero hacks,
            providing you with unparalleled protection.
          </AccordionBody>
        </Accordion>
      </div>
    </div>
  );
};

export default FaqAccordion;
