import React from "react";
import Image from "next/image";
import { useTheme } from "next-themes";
import Fade from "react-reveal/Fade";
import Link from "next/link";

const HowItWorks = () => {
  const { theme } = useTheme();

  return (
    <section>
      <div className="container relative py-[68px] pt-0 px-[20px] md:py-[98px] md:px-[24px] lg:py-[118px] lg:px-0  border border-x-0 border-t-0 dark:border-gray-200   ">
        <div className="mt-20 max-w-[600px] lg:max-w-full mx-auto  rounded-[12px] bg-white dark:bg-jacarta-900 gradient-box relative">
          <div className="grid grid-cols-1 gap-x-6 gap-y-14 md:grid-cols-2 pt-5 px-10">
            <div className="flex flex-col justify-center items-start">
              <Fade ssrReveal bottom>
                <h6 className="text-jacarta-900 font-light font-display mt-[20px] mb-4 text-left text-[30px] md:text-[44px] dark:text-white  lg:text-[48px] xl:text-[58px] leading-tight	">
                  Smart Wallets
                </h6>
              </Fade>
              <Fade ssrReveal bottom delay={350}>
                <p className="text-jacarta-900 dark:text-white mb-[28px] text-left text-[16px] md:text-[18px] font-normal ">
                Unlock the power of a fully functional smart wallet for NFTs, digital assets, and multi-chain crypto access with just a simple email verification.
                </p>
              </Fade>
              <Link
                href="/signup"
                className="items-center flex justify-center rounded-md text-[18px] font-medium  bg-gradient-to-tr from-hotPink via-purplish to-accent cursor-pointer  py-[22px] px-[32px] md:py-[24px] md:px-[38px] transition-all duration-500 hover:-translate-y-1 relative z-[100] text-white w-full md:w-[40%]"
              >
                Create Wallet
              </Link>
            </div>
            <div>
              <div className="max-w-[800px] mx-auto">
                <div className="relative w-full">
                  <Fade ssrReveal delay={350}>
                    <div className="absolute bottom-[-10%] animate-fade right-[10%] w-full h-full z-[-1] dark:mix-blend-lighten mix-blend-difference">
                      <div
                        style={{
                          position: "absolute",
                          left: "0%",
                          top: "0%",
                          right: "0%",
                          bottom: "0%",
                          zIndex: 1,

                          backgroundImage:
                            theme === "dark" || theme === "system"
                              ? "linear-gradient(90deg, #07091b, rgba(7, 9, 27, 0) 50%)"
                              : "linear-gradient(90deg, #000000, rgba(0, 0, 0, 0) 50%)",
                        }}
                      />
                    
                    </div>
                  </Fade>
                 <div className="hidden md:block">
                 <Fade ssrReveal delay={350}>
                    <Image
                      width={800}
                      height={600}
                      src="/images/integration.png"
                      className="mx-auto object-contain"
                    />
                  </Fade>
                 </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div
          className={` w-full absolute md:left-20 lg:left-0 bottom-0 md:bottom-10 lg:bottom-28 z-[0]  dark:mix-blend-lighten mix-blend-difference`}
        >
          <div className="relative  h-[300px] w-[600px] lg:w-[1200px] animate-fade  ">
            <Image
              fill
              sizes="100vw"
              src="/images/hero2.webp"
              alt="wallet-background"
              className="object-cover hidden dark:block "
            />
          </div>
        </div>
      </div>
    </section>
  );
};

export default HowItWorks;
