
import { useState } from "react";
import { MdAdd, MdRemove, MdPermIdentity, MdSyncAlt } from "react-icons/md";
import { BsCurrencyDollar } from 'react-icons/bs'

import {
  Accordion,
  AccordionHeader,
  AccordionBody,
} from "@material-tailwind/react";

function Icon({ id, open }) {
  return (
    <>
      {open === id ? (
        <MdRemove
          className="h-[49px] w-[49px] p-2 rounded-lg text-white bg-gradient-to-tr from-hotPink via-purplish to-accent  transition-all duration-500 hover:-translate-y-1 -mt-[10px] -ml-8"
        />
      ) : (
        <MdAdd
          className="h-[49px] w-[49px] p-2 rounded-lg text-white bg-jacarta-800  transition-all duration-500 hover:-translate-y-1 -mt-[10px] -ml-8"
        />
      )}
    </>
  );
}

const QAccordion = () => {

  const [open, setOpen] = useState(1);

  const handleOpen = (value) => {

    setOpen(open === value ? 0 : value);
  };

  return (
    <div className="mb-14">

      <div className="flex  gap-6  mb-10 rounded-[12px] py-[18px] md:p-[43px] border dark:border-gray-200">

        <Accordion
          className=""
          open={open === 1}
          icon={<Icon id={1} open={open} />}
        >
          <AccordionHeader
            className="accordion-button flex justify-between bg-white px-4 py-3 text-left font-display text-jacarta-900 dark:bg-jacarta-900 dark:text-white text-[16px] md:text-[24px]"
            onClick={() => handleOpen(1)}
          >

            <p className="max-w-[80%] lg:max-w-full">
              What exactly is a Web3 domain?
            </p>
          </AccordionHeader>
          <AccordionBody className="accordion-body bg-white p-4 dark:border-jacarta-600 dark:bg-jacarta-900 h-full text-base">
            A Web3 domain, also known as a decentralized domain or blockchain domain, is a unique identifier on a blockchain that replaces traditional URLs. It enables users to access decentralized websites, apps, and content without relying on traditional DNS systems.
          </AccordionBody>
        </Accordion>
      </div>

      <div className="flex gap-6 items-center mb-10 rounded-[12px] py-[18px] md:p-[43px] border  dark:border-gray-200">

        <Accordion
          className="  "
          open={open === 2}
          icon={<Icon id={2} open={open} />}

        >
          <AccordionHeader
            className="accordion-button relative flex w-full items-center justify-between bg-white px-4 py-3 text-left font-display text-jacarta-900 dark:bg-jacarta-900 dark:text-white text-[16px] md:text-[24px] "
            onClick={() => handleOpen(2)}
          >
            <p className="max-w-[80%] lg:max-w-full">
              What are the advantages of using a Web3 domain?
            </p>
          </AccordionHeader>
          <AccordionBody className="accordion-body bg-white p-4 dark:border-jacarta-600 dark:bg-jacarta-900 h-full text-base">
            Web3 domains offer several benefits, including enhanced security, censorship resistance, and ownership control. They are not controlled by centralized authorities, reducing the risk of domain hijacking or censorship. Additionally, they can simplify cryptocurrency transactions by allowing users to send funds to human-readable addresses.
          </AccordionBody>
        </Accordion>
      </div>

      <div className="flex gap-6 items-center mb-10 rounded-[12px] py-[18px] md:p-[43px] border  dark:border-gray-200">
        <Accordion
          className="  "
          open={open === 3}
          icon={<Icon id={3} open={open} />}

        >
          <AccordionHeader
            className="accordion-button text-[16px] md:text-[24px] relative flex w-full items-center justify-between bg-white px-4 py-3 text-left font-display text-jacarta-900 dark:bg-jacarta-900 dark:text-white "
            onClick={() => handleOpen(3)}
          >
            <p className="max-w-[80%] lg:max-w-full">
              What are the capabilities of Web3 domains?
            </p>
          </AccordionHeader>
          <AccordionBody className="accordion-body bg-white p-4 dark:border-jacarta-600 dark:bg-jacarta-900 h-full text-base">
            Web3 domains provide various capabilities, including linking to decentralized websites (dApps), receiving cryptocurrencies directly, and enabling personalized subdomains. They also offer a seamless way to interact with blockchain-based services, NFT marketplaces, and other decentralized applications.
          </AccordionBody>
        </Accordion>
      </div>

      <div className="flex gap-6 items-center mb-10 rounded-[12px] py-[18px] md:p-[43px] border  dark:border-gray-200">
        <Accordion
          className="  "
          open={open === 4}
          icon={<Icon id={4} open={open} />}

        >
          <AccordionHeader
            className="accordion-button text-[16px] md:text-[24px] relative flex w-full items-center justify-between bg-white px-4 py-3 text-left font-display text-jacarta-900 dark:bg-jacarta-900 dark:text-white "
            onClick={() => handleOpen(4)}
          >
            <p className="max-w-[80%] lg:max-w-full">
              Are there any renewal fees for Web3 domains?
            </p>
          </AccordionHeader>
          <AccordionBody className="accordion-body bg-white p-4 dark:border-jacarta-600 dark:bg-jacarta-900 h-full text-base">
            Unlike traditional domains, NetZylo Web3 domains don't have renewal fees. Once you've acquired a Web3 domain, you have ongoing ownership without the need to pay annual renewal fees, making them a cost-effective choice.
          </AccordionBody>
        </Accordion>
      </div>
    </div >

  );
};

export default QAccordion;
