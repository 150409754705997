import Image from "next/image";
import React from "react";
import FaqAccordion from "./accoedion";
import { Fade } from "react-reveal";
import Link from "next/link";
const Faq = () => {
  const [faq, setFaq] = React.useState(1);
  return (
    <div>
      <div className="container py-[68px] px-[20px] md:py-[98px] md:px-[24px] lg:py-[118px] lg:px-0   pt-24 dark:bg-jacarta-900  border border-x-0 border-t-0 dark:border-gray-200 pb-[60px]">
        <div className="justify-between grid lg:grid-cols-2 md:gap-10 mt-[10px] md:mt-[50px] max-w-[500px] mx-auto lg:max-w-full lg:mx-0">
          <div className="md:mr-[40px] my-[10px] md:my-[40px]">
            <Fade ssrReveal delay={400}>

              <FaqAccordion setFaq={setFaq} />


            </Fade>
          </div>
          <div className="h-full flex items-center justify-center">
            <Fade ssrReveal className='' >
              {faq === 1 ?
                <Image width={427} height={100}
                  key={faq}
                  src="/images/faq/createAccount.svg"
                  alt="search-domain"
                  className="px-[40px] lg:px-0 mx-auto  lg:mr-0  fade-in" />
                : faq === 2 ?
                  < Image width={427} height={200}
                    key={faq}
                    src="/images/faq/currency.svg"
                    alt="buy-and-own"
                    className="px-[40px] md:px-0 mx-auto  md:mr-0 fade-in" />
                  :
                  faq === 3 ? <Image width={1000} height={600}
                    key={faq}
                    src="/images/ai-domains/etherum.png"
                    alt="mint-and-transfer"
                    className="px-[40px] md:px-0 mx-auto  md:mr-0 fade-in object-cover flex items-center" /> :

                    <Image width={1000} height={600}
                      key={faq}
                      src="/images/ai-domains/bitcoin.png"
                      alt="mint-and-transfer"
                      className="px-[40px] md:px-0 mx-auto  md:mr-0 fade-in object-cover flex items-center" />
              }
            </Fade>
          </div>
        </div>
        <div className="flex items-center justify-center mt-10 md:mt-0 w-full">
          <Link href="/domains" className="items-center w-full md:w-max  justify-center rounded-md text-[18px] font-medium  bg-gradient-to-tr from-hotPink via-purplish to-accent py-[22px] px-[32px] md:py-[24px] md:px-[38px] cursor-pointer button z-[2] transition-all duration-500 text-white hover:-translate-y-1 text-center" >Start Now</Link>
        </div>

      </div>

    </div >


  );
};

export default Faq;
